<template>
  <b-card-code>
    <b-modal id="createExtraField" title="Extra oszlop hozzáadás" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-col lg="12">
          <b-form-group
            label="Oszlop neve"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="name"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group
            label="Melyik táblázatba kerüljön?"
            label-for="type"
          >
            <b-form-select
              id="type"
              v-model="type"
              :options="types"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12" class="justify-content-end d-flex my-2">
          <b-button size="sm" variant="danger" @click="$bvModal.hide('createExtraField')">
            Mégsem
          </b-button>
          <b-button class="ml-1" size="sm" variant="success" @click="createExtraField()">
            Rögzítés
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <div class="row justify-space-between mb-2 align-items-center">
      <div class="col-lg-6">
        <h4>Extra oszlopok</h4>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-primary" @click="$bvModal.show('createExtraField')">Új létrehozás</button>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="deleteComment(props.row.id)">
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span>Törlés</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Mező neve',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Szűrés mezőre',
          },
        },
        {
          label: 'Melyik táblához tartozik',
          field: 'type',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés táblázatra',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      name: '',
      type: '',
      types: ['Alkalmazott nyilvántartó', 'Jármű nyilvántartó'],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.getExtraFields()
  },
  methods: {
    createExtraField() {
      axios({
        method: 'post',
        url: 'extra-fields/create',
        data: {
          name: this.name,
          type: this.type,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen törölve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.name = ''
        this.type = ''
        this.$bvModal.hide('createExtraField')
        this.getExtraFields()
      })
    },
    deleteComment(id) {
      axios({
        method: 'delete',
        url: `extra-fields/delete/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen törölve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.getExtraFields()
      })
    },
    getExtraFields() {
      axios({
        method: 'get',
        url: 'extra-fields/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.rows = res.data
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
